@media screen and (max-width:1366px){
	

}/*max-width:1366px*/



@media screen and (max-width:1200px){

	

}/*max-width:1200px*/



@media screen and (max-width:1199px){

	.travel-budget h3 {font-size: 15px;}
	.price_filter {margin-top: 5px;}
	.travel-select-icon select, .travel-check-icon input {font-size: 12px;}
	.travel-select-icon:after { padding: 10px 3px;}
	.single-tab-select-box h2 {font-size: 15px;}

	.item-title { bottom: 6%;left: 15px;}

	.thumbnail h2 {line-height: 1.5;}
	.blog-content .thumbnail { height: 540px;}
	.blog-content .caption .blog-txt a { padding-bottom: 0px;margin-top:0px;}
	
}/*max-width:1199px*/

@media screen and (max-width:1100px){

    

}/*max-width:1100px*/



@media screen and (max-width:1099px){

    

}/*max-width:1099px*/

@media screen and (max-width:1024px){


}/*max-width:1024px*/





@media screen and (max-width:992px){


}/*max-width:992px*/





@media screen and (max-width:991px){

	.main-menu .nav li {padding: 20px 5px 0px;}
	.navbar-collapse {padding-right: 40px;padding-left: 0px;}
	.main-menu .nav li a {font-size:13px;padding: 11px 1px 29px;}
	.book-btn {width: 100px;font-size: 13px;}

	.about-us h2 { font-size: 64px;}

	.price_slider_amount > input[type="text"] {width: 160px;}

	/*.appsLand-btn {padding: 17px 35px;}*/

	.single-special-shape-txt {left: 25px;}

}/*max-width:991px*/



@media screen and (max-width:768px){


}/*max-width:768px*/





@media screen and (max-width:767px){
	#sticky-wrapper{background: rgba(77,78,84,1);}
	.sticky-wrapper .navbar-collapse.collapse.in{background: rgba(77,78,84,1);width: 112%;margin-left: -14px;padding-left: 27px;}
	.logo {float: left;}
	.main-menu .navbar-toggle {float: right;margin: 17px 0;}
	.navbar-collapse {padding-right: 15px;padding-left: 15px;}
	.main-menu .nav li a {font-size: 14px;padding: 0px 1px 10px;display: inline-block;}
	.sticky-wrapper.is-sticky .main-menu .nav a:before {bottom: 0px;}

	.about-us h2 {font-size: 43px;}
	.about-us-txt { max-width: 450px;}

	.travel-budget h3 {margin-bottom: 20px;}

	.single-service-box {flex-direction: column;height: auto;}
	.service-content {margin: 0 auto; margin-top: 30px;}

	.single-package-item {max-width: 450px;margin: 0 auto;margin-bottom: 30px;}

	.single-special-offer {margin-bottom: 30px;}

	.home1-testm-single {max-width: 345px;margin: 0 auto;}

	.blog-content {max-width: 400px;margin: 30px auto;}
	.blog-content .thumbnail .thumbnail-img img,.thumbnail-img-overlay{width: 100%; margin-left: 0px;}
	.thumbnail-img-overlay{left: 0px;}
	
	.single-footer-item {margin-bottom: 30px;}
	.footer-logo {padding-top: 0;}

}/*max-width:767px*/



@media screen and (max-width: 660px){

	.home1-testm-single {max-width: 350px;margin: 0 auto;}
	.main-menu .nav li a {font-size: 14px;}

}/*max-width:660px*/



@media screen and (max-width: 640px){
	
	.desc-tabs .nav-tabs>li>a { padding: 17px 10px 17px 10px;}

	.blog-head h2 {font-size: 18px;}

}/*max-width:640px*/



@media screen and (max-width: 540px){

	.filtr-item {max-width: 350px;margin: 0 auto;}

	.subscribe-title h2 {font-size: 28px;}

}/*max-width:540px*/

@media screen and (max-width: 480px){

	.about-us h2 {font-size: 30px;}

	.time-after:after{display: none;}

	.single-package-item {max-width: 350px;margin: 0 auto;margin-bottom: 30px;}

	.single-special-shape-txt {left: 30px;}

}/*max-width:480px*/



@media screen and (max-width: 440px){
	.desc-tabs .nav-tabs>li>a,.desc-tabs .nav-tabs>li.active>a { padding: 17px 10px 17px 10px;font-size:12px;}
	.nav-tabs {border-bottom: 0;}

	.single-trip-circle:nth-child(2){margin-left: 0px;}
	.trip-circle {flex-direction: column;}
	.about-view.travel-btn {width: 150px;}
	
	.filtr-item {max-width: 250px;}

	.home1-testm-txt p {font-size:14px;max-width:300px;}

	.dicount-offer-content h2 {font-size: 24px;}

	.single-package-item {max-width: 250px;margin: 0 auto;margin-bottom: 30px;}

	.about-view.packages-btn.offfer-btn { margin-right: 10px;}

	.custom-input-group .subscribe-btn {float: left;top: 76px;}
	.custom-input-group .form-control {padding-right: 0px;font-size: 13px;}


}/*max-width:400px*/



@media screen and (max-width:350px){
	
	.travel-select-icon select, .travel-check-icon input {font-size: 10px;}
	.travel-check-icon:after{ padding: 10px 5px;}

	.subscribe-title h2 {font-size: 20px;}
	.subscribe-title p {font-size: 14px;}
	
	
}/*max-width:350px*/

@media screen and  (max-width: 320px){
	.dicount-offer-content h2 {font-size: 16px;}

}/*max-width:320px*/



