.about-us{
    padding-top:100px;
	display: flex;
    justify-content: center;
    align-items: center;
	position: relative;
	background: url(./assets/images/home/banner.jpg)no-repeat; 
	background-size: cover;
	background-position: center;
	min-height: 800px;
    z-index: 10;
}
.about-us:before{
	position: absolute;
	content: "";
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(9, 119, 198, 0.2)
   
}

/*about-us-content*/
.about-us-content{
	margin: -200px 0 0 0;
}
.about-us h4 {
    margin: 100px 0 0 0;
	color:#fff;	
	font-size:68px;
	text-transform: inherit;
	max-width: 800px;
	font-weight: 600;
	line-height: 1.2;
}
.about-us h2 span{
	color:#2bdab5;
	margin-top: -11px;
}
.service {
    position: relative;
    padding: 80px 0 54px;
}
/* single-ststistics-box */
.single-service-box {
    display: flex;
    margin-bottom:30px;
}
/* single-ststistics-box */
.service-content {
    max-width: 268px;
    margin-left: 7px;
    margin-top: 6px;
    margin:6px 0 30px 7px;
    
}
.service-content h2 a{
    color:#565a5c;
    font-size:16px;
    text-transform:capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.service-content p{
    color:#aaa;
    font-size:14px;
    text-transform:inherit;
    font-family: 'Poppins', sans-serif;
    margin-top: 17px;
}
.gallery{
    background: #f9f9f9;
}
.gallery-details{
    /* margin-top:117px; */
    margin-bottom:109px;
}
.gallery-content {
    margin-top:62px;
}

/* gallary-header  */
.gallary-header h2{
    position:relative;
    color:#565a5c;  
    font-size:30px;
    font-weight:500;
    text-transform:capitalize;
    font-family: 'Poppins', sans-serif;
    padding-bottom:14px;
    padding-top: 34px;
}
.gallary-header p{
    position:relative;
    color:#aaa;  
    font-size:16px;
    font-family: 'Poppins', sans-serif;
    margin-top: 17px;
}


/* ---- .item ---- */
.filtr-container .filtr-item {
    overflow:hidden;
    float: left;
    position:relative;
    margin-bottom: 30px;
    overflow:hidden;
}
/*.item img*/
.filtr-container .filtr-item img{
    width:100%;
    height:100%;
    transition:.5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.filtr-container .filtr-item:hover  img{
    -webkit-transform:scale(1.5);
    -moz-transform:scale(1.5);
    -ms-transform:scale(1.5);
    -o-transform:scale(1.5);
    transform:scale(1.5);
}/*.item img*/



/*.item-title*/
.item-title{
    position: absolute;
    background:transparent;
    bottom: 20%;
    left: 60px;
    width: 170px;
    height: 60px;
    -webkit-transition:all 0.6s linear;
    -moz-transition:all 0.6s linear;
    -ms-transition:all 0.6s linear;
    -o-transition:all 0.6s linear;
    transition: all 0.6s linear;
    
}
.item-title a {
    color:#fff;
    font-size:34px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    margin-bottom: 3px;
    -webkit-transition:all 0.6s linear;
    -moz-transition:all 0.6s linear;
    -ms-transition:all 0.6s linear;
    -o-transition:all 0.6s linear;
    transition: all 0.6s linear;
}
.item-title p {
    color:#fff;
    font-size:16px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    -webkit-transition:all 0.6s linear;
    -moz-transition:all 0.6s linear;
    -ms-transition:all 0.6s linear;
    -o-transition:all 0.6s linear;
    transition: all 0.6s linear;
}
.item-title p span{
    padding-right: 30px;
}
.filtr-container .filtr-item:hover .item-title a,
.filtr-container .filtr-item:hover .item-title p{
    color:#2cdbb5;
}
.packages{
    padding:20px 0 90px;
}
.packages-content{
    margin-top:65px;
}
/*single-package-item */
.single-package-item{
    margin-bottom: 30px;
    padding-bottom: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;
}
.single-package-item-txt{
    padding: 0 25px;
}
.single-package-item img {
    width: 100%;
}
.single-package-item h3{
    position: relative;
    font-size: 20px;
    color: #565a5c;
    font-weight:500;
    font-family: 'Poppins', sans-serif;
    padding: 20px 0;
}
.single-package-item h3:after{
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ebebeb;
}
.packages-para{
    padding:17px 0 0;
    text-transform: capitalize;
}
.packages-para p{
    font-size: 14px;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    margin-bottom:15px;
}
.about-btn {
    margin-top: 39px;
    letter-spacing: 1.2px;
     -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}
.about-btn:hover .about-view{
    color: #fff;
    background: #00d8d5;
    box-shadow: 0 5px 20px rgba(14,15,18,.7);
	border: 1px solid #00d8d5;
}
.packages-para p span{
    display: inline-block;
    width: 150px;
}
.packages-review p i{
    color: #ffdf00;
    font-size: 16px;
}
.packages-review span{
    margin-left: 20px;
    font-size: 14px;
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}
.about-view.packages-btn{
    width: 110px;
    height: 35px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;

}
.about-view.packages-btn:hover{
    box-shadow: 0 5px 20px rgba(14,15,18,.3);
}
.single-package-item:hover{
    box-shadow: 0 0 20px rgba(0,0,0,.2);
}
.about-view {
	display: flex;
    justify-content: center;
    align-items: center;
    background: #00d8ff;
    border: 1px solid #00d8ff;
    width: 200px;
    height: 60px;
    white-space: nowrap;
    color: #fff;
    font-size:16px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(14,15,18,.2);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.testemonial{
    position:relative;
    background: #f9f9f9;
    padding:120px 0;
}

/*testemonial-carousel*/
.home1-testm-single {
    /* max-width: 345px; */
    background: #fff;
    padding: 20px 0;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    -webkit-transition: .5s; 
    -moz-transition:.5s; 
    -ms-transition:.5s; 
    -o-transition:.5s;
    transition: .5s;
   
    
}
.home1-testm-single:hover {
    box-shadow: 0 0 30px rgba(0,0,0,.3);
}
.home1-testm.item {
    padding-top: 74px;
    padding-bottom: 30px;
  
}

.home1-testm-img img{
   
    width:80px;
    height: 80px; 
    border-radius: 50%;
   
}
.testemonial .owl-carousel .owl-item img {
    display: inline-block;
    width:80px;
    height: 80px;
    line-height: 80px;
    border-radius: 50%;
   
}/*.home1-testm-img*/

/*owl-carousel*/
.testemonial .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    padding: 0 19px;
    background-color: #ffdf00;
}
.testemonial .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 20px;
    background-color: #ffdf00;
}
.testemonial .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 5px 3px;
    border-radius:50%;
    background: #aaa; 
}
.testemonial .owl-theme .owl-dots .owl-dot.active span{
    background: #00d8ff;
}/*owl-carousel*/

/*home1-testm-txt*/
.home1-testm-txt {
    padding-top: 27px;
}
.home1-testm-txt p{
    color: #aaa;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    min-height: 160px;
    margin: 0 auto;
    padding: 16px;
}
.home1-testm-txt h3 a{
    display: block;
    color: #00d8ff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
}
.home1-testm-txt h4{
    color: #aaa;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    padding: 15px 0 0px;
}
.home1-testm-txt span {
    color: #ddd;
    font-size: 18px;
}/*home1-testm-txt*/


.packages-para.special-offer-para p{
    color: #fff;
}
.packages-para.special-offer-para p span{
    display: inline;
    color: #fff;
    width: 100%;
    margin-right: 25px;
}
.offer-para{
    margin-top: 31px;
    text-transform: initial;
    line-height: 2;
}

.packages-review.special-offer-review {
    margin-bottom: 13px;
}
.packages-review.special-offer-review span{
    color: #fff;
}

.offer-btn-group{
    display: flex;
    margin-top: -10px;
}
.about-view.packages-btn.offfer-btn{
    background: transparent;
    border:1px solid #fff;
    color: #fff;
    margin-right: 25px;
}
.blog{
    background: #fff;
        padding: 115px 0 92px;
}

/*blog-content*/
.blog-content {
    margin-top: 66px;
    
}
.thumbnail{
    min-height: 530px;
}
.blog-content .thumbnail {
    background:transparent;
    border:transparent;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    margin-bottom:30px;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.blog-content .thumbnail:hover {
    box-shadow: 0 0 20px rgba(0,0,0,.2);
}

.thumbnail h2{
    padding: 25px 0 25px 20px;
    color:#565a5c;
    font-size:16px;
    font-family: 'Poppins', sans-serif;
}
.thumbnail span{
    color:#aaa;
    font-size:14px;
    font-weight: normal;
    padding-left: 25px;
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none;
}
/*.thumbnail-img*/
.thumbnail-img{position:relative;width: 100%;}

.blog-content .thumbnail .thumbnail-img  img{
    max-width: 100%;
    /* margin-left: -4px; */
}
.thumbnail-img-overlay{
    position:absolute;
    top:0;
    left:-4px;
    width:102%;
    height:100%;
    background:rgba(0,0,0,.5);
    opacity:0;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.blog-content .thumbnail:hover  .thumbnail-img-overlay{
    opacity:1;
}/*.thumbnail-img*/

/*.caption*/
.blog-content .caption {
    background:#fff;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.blog-content .thumbnail:hover  .caption {
    border:transparent;
    
}
.blog-txt {
    margin-top:8px;
    margin-left:12px;
    padding: 3px;
}
.blog-content .caption .blog-txt  h3 a{
    color:#565a5c;
    font-size:15px;
    font-weight:500;
    font-family: 'Poppins', sans-serif;
    margin-top:15px;
    padding-bottom: 3px;
    min-height: 63px;
    line-height: 2;
}
.blog-content .caption .blog-txt p{
    color:#aaaaaa;
    font-size:14px;
    font-family: 'Poppins', sans-serif;
    min-height: 105px
}
.blog-content .caption .blog-txt a {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #64cbfa;
    padding-bottom: 12px;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.footer-copyright{
    background:#f9f9f9;
    padding: 0 0 60px;
}
.footer-content{
    padding:120px 0;
}

/*single-footer-item*/
.single-footer-item{
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}
.single-footer-item h2{
    color:#565a5c;
    font-size:16px;
    font-weight: 600;
    margin-bottom:40px;
}/*single-footer-item*/

/*footer-logo*/
.footer-logo {
    padding-top: 76px;
}
.footer-logo a, .footer-logo a:hover,.footer-logo a:focus {
    color: #565a5c;
    font-size:24px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    margin: 14px 0px 0;
    padding: 10px 0 0 0;
}
.footer-logo a>span {
    color: #00d8ff;
    text-transform: capitalize;
} 
.footer-logo p{
    color: #aaa;
    font-size:16px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}
/*footer-logo*/

/*single-footer-txt*/
.single-footer-txt p{
    margin-bottom:8px;
}
.single-footer-txt p a{
    color:#565a5c;
    font-size:16px;
    -webkit-transition: .5s ease-in-out; 
    -moz-transition:.5s ease-in-out; 
    -ms-transition:.5s ease-in-out; 
    -o-transition:.5s ease-in-out;
    transition: .5s ease-in-out;
    
}
.single-footer-txt p a:hover{
    -webkit-transition:scaleX(1.2);
    -moz-transition:scaleX(1.2);
    -ms-transition:scaleX(1.2);
    -o-transition:scaleX(1.2);
    transform:scaleX(1.2);
    color: #00d8ff;
}/*single-footer-txt*/

.footer-copyright hr{
    border-top: 1px solid #ebebeb;
}

/*foot-icons*/
.foot-icons {
    text-align: center;
    margin-top:60px;
}
 .foot-icons .footer-social-links li {
    padding: 0 10px;
    text-align: center;
}
.foot-icons .footer-social-links a {
    color: #fff;
    display: block;
    font-size: 18px;
    width: 60px;
    line-height: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    border-radius: 50%;
}

.foot-icon-bg-1{
    background: #3b5999;
}
.foot-icon-bg-2{
    background: #1da1f3;
}
.foot-icon-bg-3{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8938ae+0,c13181+100 */
    background: #8938ae; /* Old browsers */
    background: -webkit-linear-gradient(right, #8938ae 0%, #c13181 100%);
    background: -o-linear-gradient(right, #8938ae 0%, #c13181 100%);
    background: linear-gradient(to right, #8938ae 0%, #c13181 100%);
}

.foot-icons .footer-social-links a:hover {
    /*color: #00d8d5;*/
    color: #2cdbb5;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
.foot-icons p{
    color:#aaa;
    font-size:16px;
    margin-top:28px;
}
.foot-icons p a,.foot-icons p a:hover,.foot-icons p a:focus{
    color:#00d8ff;
    font-size:16px;
    margin-top:28px;
}/*foot-icons*/

/*===============================
         Scroll Top
===============================*/

#scroll-Top  .return-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: none;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
    z-index:15;
    color: #fff;
    background:#00d8ff;
	border:1px solid #00d8ff;
	border-radius:5px;
	-webkit-transition: .5s; 
	-moz-transition:.5s; 
	-ms-transition:.5s; 
	-o-transition:.5s;
    transition: .5s;
	z-index: 2;

}

#scroll-Top  .return-to-top:hover {
    background:#2cdbb5;
    color: #fff;
	border-radius:50%;

}


