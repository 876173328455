#loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #1530cb;
  width: 42px;
  height: 42px;
  animation: spin 1s linear infinite;
  visibility: hidden;
  z-index: 1000000000000000;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.center {
  position: absolute;
  top: 0rem;
  bottom: 0;
  left: 0rem;
  right: 0;
  margin: auto;
}

#magic::before {
  content: "Please wait, your itinerary is loading....";
  display: block;
  text-align: center;
  margin-top: 22px;
}

#magic {
  visibility: hidden;
}
