
body {
  margin: 0;
  padding: 0;
  font-family:  sans-serif;
  background-color: #ffffff;
}

h2{
  color:white;
  font-size: 28px;
}

.mycontainer {
  max-width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navbar {
  height: 56px;
  /* background-color: #ffffff; */
  padding:0 16px;
  position: relative;
  background-color: rgb(68, 170, 233);
}

.logo {
  margin-left: 0px;
}

.menu-icon {
  display: none;
  margin-right: 0px;
}

.nav-elements {
  display: flex;
  align-items: center;
}

.nav-elements ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  margin:0px !important;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 48px;
}

.nav-elements ul a{
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}
.nav-elements ul button{
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.nav-elements ul a.active {
  color: #ffffff;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    
   
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 0;
    /* height: calc(40vh - 30px); */
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 100%;

    /* height:calc(25vh - 25px); */
    height:auto
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom:20px ;
  }

  .nav-elements ul li {
    /* margin-right: unset; */
    margin-right: 30px;
    margin-top: 22px;
  }
}