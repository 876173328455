
.auth-form-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form {
  width: 100%;
  max-width: 500px;
  margin: 8px auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding-bottom: 50px;
}

.auth-form h1 {
  margin: 40px 0;
  color: #1b4388;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-field label {
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
  color: #1b4388;
}

.input-wrapper {
  padding: 2px;
  border-radius: 7px;
  background: #1b4388;
  background: linear-gradient(
    25deg,
    rgba(27, 67, 136, 1) 0%,
    rgba(30, 144, 194, 1) 100%
  );
}

.input-field input {
  min-width:280px;
  width: 100%;
  max-width: 420px;
  height: 32px;
  border-radius: 4px;
  padding: 15px;
  font-size: 14px;
  border: none;
  background-color: #fff;
  outline: none;
}

.auth-form-submit {
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom:16px;
  cursor: pointer;
  color: white;
  background: #1b4388;
  background: linear-gradient(25deg, #1b4388 0%, #1e90c2 100%);
}

.google-auth {
  height: 36px;
  width: 100%;
  min-width:280px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: #1b4388;
  position: relative;
  padding: 10px
}

.google-auth img {
  position: absolute;
  left: 10px;
  top: 50%;
  padding:2px;
  transform: translateY(-50%);
}

.google-auth p {
  padding-top: 12px;
  margin-left: 10px;
}

.redirect {
  margin-top: 10px;
  color: #1b4388;
}

.redirect span {
  text-decoration: underline;
  cursor: pointer;
  color: #1e90c2;
}

.horizontal-divider {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.horizontal-divider p {
  margin: 0 10px 2px 10px;
  color: #1e90c2;
}

.line-right,
.line-left {
  height: 1px;
  max-width: 145px;
  min-width: 120px;
}

.line-left {
  background: #1b4388;
  background: linear-gradient(90deg, #1b4388 0%, #1e90c2 100);
}
.line-right {
  background: #1b4388;
  background: linear-gradient(90deg, #1e90c2 0%, #1b4388 100%);
}

.error {
  margin-top: 10px;
  color: red;
  font-size: 15px;
}

.fb-img{
  width: 11%;
height: 60%;
  
}
.mt{
  margin-top: 10px;
} 