* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: sans-serif;
}

.logo{
  display: flex;
  gap:1rem;
  align-items: center;
}